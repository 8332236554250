.ant-layout-content {
  background: #fff !important;
}
.ant-tabs-nav .ant-tabs-tab {
  /* position: relative;
  display: inline-block;
  box-sizing: border-box;*/
  /* height: 50px !important;  */
  margin: 5px 0px 5px 0px !important;
  padding: 5px 10px 5px 10px !important;
}
.anticon-filter {
  margin: 0px 10px;
}
.ant-tabs-bar {
  margin: 0px !important;
}
.ant-modal-wrap {
  z-index: 1000 !important;
}
.ant-tooltip {
  max-width: 400px !important;
}
.ant-card-body {
  margin: 10px !important;
  padding: 0px !important;
}
.ant-card-bordered {
  border: none !important;
}
.ant-spin {
  text-align: center;
  margin-left: 50%;
  margin-right: 50%;
  margin-top: 25%;
}
.App {
  text-align: center;
}
body {
  background-color: #ffffff !important;
  min-height: 100vh;
}
#root {
  height: 100%;
}
/* stores the layout of the settings icon */
.amcharts-amexport-menu {
  /*This is becuase of the Settings Icon for Amcharts is blocked*/
  margin: 10px !important;
}
.ant-btn-icon-only {
  /*This is becuase of the Settings Icon for Amcharts is blocked by the .amcharts-amexport-menu*/
  z-index: 999;
  background: none;
}
.settings-icon-layout {
  position: absolute;
  top: -10px;
  right: 0px;
  z-index: 999;
  background-color: #0089c7;
}
.settings-layout {
  position: absolute;
  top: -5px;
  right: 0px;
  z-index: 999;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.force-inline {
  display: inline-block;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#view-header {
  /* position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100px; */
  /*padding: 0px !important;*/
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .ant-tabs-nav-scroll {
    visibility: hidden;
  }
  #view-header {
    position: fixed;
    z-index: 1;
    top: 0;
  }
  #view-footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    page-break-before: always;
    break-before: always;
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .force-relative {
    position: relative !important;
    display: inline-block !important;
  }
  .float-left {
    display: block;
    float: left;
  }
  .view-content {
    page-break-inside: avoid !important;
    display: relative !important;
  }
  .break-before {
    page-break-before: always;
  }
  .break-after {
    page-break-after: always;
  }
}
.card-center {
  text-align: -moz-center;
  text-align: -webkit-center;
}
.ql-editor {
  padding: 0 !important;
}
/*
CSS file utilized to have styles in the Dashboard Page. We should from now on if going to add css styles use global files like this
Or create a css file for the component in a folder of their own
*/
.center-empty-page {
  text-align: 'center';
  padding: 12;
}
.hide {
  display: none;
}
.block {
  display: block;
}
.full-width {
  width: 100%;
}
.view-tab.label {
  margin-right: 3;
}
.find-a-autocompletes {
  width: 200;
}
.share-button {
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 0;
}
.print-button {
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 90px;
}
.footer-grid-layout-default-styles {
  min-height: 80px;
  max-height: 100px;
}
.header-grid-layout-default-styles {
  min-height: 80px;
  max-height: 100px;
}
.update-toggle-buttons {
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 173px;
}
.update-toggle-button-save {
  background-color: #33cc33 !important;
  border-color: #33cc33 !important;
}
.grid-layout {
  /* margin-left: 18px;
  margin-right: 18px;
  margin-top: 6px; */
  margin: 0px;
}
.dashboard-save-icon {
  position: absolute;
  top: 5px;
  right: 30px;
}
.dashboard-mini-banners {
  /*text-align: center;
  position: static;
  top: 0; */
  z-index: 1;
  position: fixed;
  left: 257px;
  bottom: 0;
}
.statistics-mini-banners-save {
  position: fixed;
  left: 257px;
  z-index: 1;
  bottom: 0;
}
.statistics-mini-banners-create {
  position: fixed;
  left: 305px;
  z-index: 1;
  bottom: 0;
}
/* These are already in /src/pages/DashboardPage.css
.dashboard-header {
  padding-bottom: 25px;
}
.dashboard-footer {
  bottom: 0;
  padding-bottom: 25px;
} 

.dashboard-body {
  padding: 0px;
}
*/
.wheelcog-tabs {
  margin-left: 5px;
}
.wheelcog-tabs-parent {
  margin: auto;
}
.menu-edit-mode {
  margin-left: 4px;
}
.map-icon {
  font-size: 32px;
}
.map-modal-options {
  text-align: center;
}
.map-container-class {
  width: 100%;
  height: 100%;
}
.green-btn {
  background-color: #18f21c;
  border-color: #18f21c;
}
.green-btn:hover {
  background-color: #18f21c;
  border-color: #18f21c;
  opacity: 0.7;
}
.geospatial-lookup {
  width: 250px;
}
.export-chart-thumbnail {
  position: absolute;
  top: 5px;
  right: 55px;
}
.remove-hyperlink {
  color: rgba(0, 0, 0, 0.65);
}
.as-hyper-link {
  color: #0089c7;
  cursor: pointer !important;
  transition-delay: 0.1s;
  z-index: -2;
}
.as-hyper-link:focus,
.as-hyper-link:hover {
  opacity: 0.66;
}
.form-item-styles {
  height: 0px;
  margin: 5px;
}
.item-text-highlight {
  transition: 0.5s;
  cursor: pointer;
}
.item-text-highlight:hover {
  color: #2693ff;
}
.ink {
  opacity: 0.5;
  z-index: 99;
  top: 0;
  left: 0;
  display: block;
}
.inline-block {
  display: inline-block;
}
.upload-view-item-media-enable {
  cursor: pointer;
}
.select-3-4 {
  width: 75%;
}
.droppable-container {
  border: 1px solid grey;
  transition: background-color 0.2s ease;
  padding: 6px;
  width: 250px;
}
.draggable-item {
  /* some basic styles to make the items look a bit nicer */
  -webkit-user-select: none;
  user-select: none;
  padding: 12px;
  margin: 0 0 6px 0;
  transition: background-color 0.2s ease;
}
.draggable-item:last-of-type {
  margin: 0;
}
